import React, { useEffect, useMemo, useState } from "react";

export interface SeasonCardProps {
  imageSrc: string;
  name: string;
  description: string;
  startDate: Date;
}

export const SeasonCard: React.FC<SeasonCardProps> = ({
  imageSrc,
  name,
  description,
  startDate,
}) => {
  const [dayTexts, setDayTexts] = useState({} as Record<string, string>);
  const [editingDay, setEditingDay] = useState(null as null | string);

  useEffect(() => {
    const savedTexts = localStorage.getItem(
      `dayTexts-${startDate.toDateString()}`
    );
    if (savedTexts) {
      setDayTexts(JSON.parse(savedTexts));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(dayTexts).length === 0) return;
    localStorage.setItem(
      `dayTexts-${startDate.toDateString()}`,
      JSON.stringify(dayTexts)
    );
  }, [dayTexts]);

  const generateDays = () => {
    let days = [];
    let tempDate = new Date(startDate);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    tempDate.setHours(0, 0, 0, 0);

    let loopCount = 14; // Default to 14 days
    for (let i = 0; i < 14; i++) {
      tempDate.setDate(tempDate.getDate() + 1);
      if (tempDate.getMonth() === 1 && tempDate.getDate() === 29) {
        loopCount = 15; // Extend to 15 days
        break;
      }
    }

    // Reset tempDate
    tempDate = new Date(startDate);
    tempDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < loopCount; i++) {
      const showMonth = tempDate.getDate() === 1;
      const dayOfWeek = tempDate.toLocaleString("default", {
        weekday: "short",
      });

      const isToday = tempDate.getTime() === currentDate.getTime();
      const dateString = tempDate.toISOString().split("T")[0];
      const isEditing = editingDay === dateString;

      days.push(
        <div
          key={i}
          className={`border flex flex-col items-center justify-start p-1 w-[7rem] h-[7rem] ${
            isToday ? "bg-blue-200" : ""
          }`}
          onClick={() => setEditingDay(dateString)}
        >
          <div>
            <span>{tempDate.getDate()}</span>
            {showMonth && (
              <span>
                {" "}
                {tempDate.toLocaleString("default", { month: "short" })}
              </span>
            )}
          </div>
          <span>{dayOfWeek}</span>
          {isEditing ? (
            <textarea
              className="w-[6rem] h-[4rem] p-1"
              autoFocus
              value={dayTexts[dateString] || ""}
              onChange={(e) => {
                setDayTexts({
                  ...dayTexts,
                  [dateString]: e.target.value,
                });
              }}
              onBlur={() => setEditingDay(null)}
            />
          ) : (
            <div>{dayTexts[dateString]}</div>
          )}
        </div>
      );

      tempDate.setDate(tempDate.getDate() + 1);
      tempDate.setHours(0, 0, 0, 0);
    }

    return days;
  };

  const days = useMemo(generateDays, [startDate, dayTexts, editingDay]);

  return (
    <div className="max-w-4xl rounded overflow-hidden shadow-lg bg-white mt-2 mb-2">
      <img className="w-full" src={imageSrc} alt={name} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{name}</div>
        <p className="text-gray-700 text-base h-12">{description}</p>
      </div>
      <div className="px-6 pt-4 pb-2 grid grid-cols-3 sm:grid-cols-[repeat(auto-fill,minmax(7rem,1fr))] gap-1">
        {days}
      </div>
    </div>
  );
};

export default SeasonCard;
