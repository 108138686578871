import React, { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { flushSync } from "react-dom";
import SeasonCard from "./SeasonCard";
const ColorThief = require("colorthief/dist/color-thief.mjs").default;

console.log(ColorThief);
const colorThief = new ColorThief();

const TWEEN_FACTOR = 40;

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max);

interface Season {
  imageSrc: string;
  name: string;
  description: string;
  startDate: Date;
}

interface SeasonsCarouselProps {
  seasons: Season[];
}

const SeasonsCarousel: React.FC<SeasonsCarouselProps> = ({ seasons }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [tweenValues, setTweenValues] = useState<number[]>([]);
  const emblaRef2 = useRef(null as any);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();

    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      return numberWithinRange(tweenValue, 0, 1);
    });
    setTweenValues(styles);
  }, [emblaApi, setTweenValues]);

  useEffect(() => {
    if (!emblaApi) return;

    const changeBackgroundColor = () => {
      const centeredSlideIndex = emblaApi.selectedScrollSnap();
      const imgElement =
        emblaRef2.current.querySelectorAll("img")[centeredSlideIndex];

      if (imgElement.complete) {
        const color = colorThief.getColor(imgElement);
        const rgbString = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
        emblaRef2.current.style.backgroundColor = rgbString;
        emblaRef2.current.style.transition = "background-color 0.5s ease";
      }
    };

    emblaApi.on("select", changeBackgroundColor);

    setTimeout(changeBackgroundColor, 1000);

    const today = new Date();
    let currentSeasonIndex = 0;
    for (let i = 0; i < seasons.length; i++) {
      const { startDate } = seasons[i];
      if (startDate <= today) {
        currentSeasonIndex = i;
      } else {
        break;
      }
    }

    // Scroll to the current season
    emblaApi.scrollTo(currentSeasonIndex);

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);
  }, [emblaApi, onScroll]);

  return (
    <div className="embla" ref={emblaRef2}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {seasons.map((season, index) => (
            <div
              className="embla__slide"
              key={index}
              style={{
                ...(tweenValues.length && { opacity: tweenValues[index] }),
              }}
            >
              <SeasonCard
                imageSrc={season.imageSrc}
                name={season.name}
                description={season.description}
                startDate={season.startDate}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeasonsCarousel;
