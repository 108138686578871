import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { SeasonCard } from "./components/SeasonCard";
import SeasonsCarousel from "./components/SeasonsCarousel";

const seasonsData = [
  {
    name: "Scorching Summer Days",
    description: "Peak of summer with high temperatures.",
    startDate: new Date("2023-01-01"),
  },
  {
    name: "Flowering Gum Trees",
    description:
      "Eucalyptus trees display their vibrant blossoms, attracting birds like lorikeets and honeyeaters.",
    startDate: new Date("2023-01-15"),
  },
  {
    name: "Coastal Waters Warmest",
    description:
      "Sydney's beaches see a surge in activity as the ocean temperatures reach their warmest.",
    startDate: new Date("2023-01-29"),
  },
  {
    name: "Dragonflies Darting",
    description: "Often seen near water bodies, signaling the end of summer.",
    startDate: new Date("2023-02-12"),
  },
  {
    name: "Last of Summer Harvest",
    description:
      "Gardens and markets showcase the final bounty of summer fruits and produce.",
    startDate: new Date("2023-02-26"),
  },
  {
    name: "Autumn Leaves Begin",
    description: "Deciduous trees start to change color.",
    startDate: new Date("2023-03-12"),
  },
  {
    name: "First Autumn Rains",
    description: "Refreshing rain that brings relief after summer.",
    startDate: new Date("2023-03-26"),
  },
  {
    name: "Misty Harbour Mornings",
    description:
      "The harbor often gets a misty appearance in the early morning.",
    startDate: new Date("2023-04-09"),
  },
  {
    name: "Bird Migration Begins",
    description: "Many birds start their autumn migration.",
    startDate: new Date("2023-04-23"),
  },
  {
    name: "Chilly Mornings Emerge",
    description: "Noticeable drop in temperature, especially in the mornings.",
    startDate: new Date("2023-05-07"),
  },
  {
    name: "Vivid Sydney Lights",
    description: "The city comes alive with light art and projections.",
    startDate: new Date("2023-05-21"),
  },
  {
    name: "Brisk Winter Winds",
    description:
      "Describing the increasing cold winds typical of early winter.",
    startDate: new Date("2023-06-04"),
  },
  {
    name: "Longest Nights",
    description: "Winter solstice marking the shortest day of the year.",
    startDate: new Date("2023-06-18"),
  },
  {
    name: "Whale Migration Peak",
    description: "Whales journey to warmer waters.",
    startDate: new Date("2023-07-02"),
  },
  {
    name: "Frosty Mornings",
    description: "Rare frost in certain parts of Sydney.",
    startDate: new Date("2023-07-16"),
  },
  {
    name: "Cozy Winter Evenings",
    description: "Cold nights perfect for warm gatherings indoors.",
    startDate: new Date("2023-07-30"),
  },
  {
    name: "Blossoming Cherry Trees",
    description: "Heralding the upcoming spring.",
    startDate: new Date("2023-08-13"),
  },
  {
    name: "Early Spring Stirrings",
    description: "Buds begin to form, and days get slightly warmer.",
    startDate: new Date("2023-08-27"),
  },
  {
    name: "First Waratah Blooms",
    description: "New South Wales' floral emblem starts its bloom.",
    startDate: new Date("2023-09-10"),
  },
  {
    name: "Spring Thunderstorms",
    description: "With the warming weather come the first storms.",
    startDate: new Date("2023-09-24"),
  },
  {
    name: "Jacaranda Blooms",
    description: "Streets and parks are covered in purple blossoms.",
    startDate: new Date("2023-10-08"),
  },
  {
    name: "Sculptures by the Sea",
    description:
      "The coastal walk from Bondi to Tamarama transforms into a seaside art gallery.",
    startDate: new Date("2023-10-22"),
  },
  {
    name: "Beach Season Kick-off",
    description:
      "The unofficial start of the beach season as locals flock to the beaches.",
    startDate: new Date("2023-11-05"),
  },
  {
    name: "Christmas Beetles Appear",
    description: "Announcing the start of the festive season.",
    startDate: new Date("2023-11-19"),
  },
  {
    name: "Cicada Song Peaks",
    description:
      "Their loud chorus fills the evenings, marking the start of summer.",
    startDate: new Date("2023-12-03"),
  },
  {
    name: "Holiday Festivities Begin",
    description: "Preparations and celebrations for the holiday season.",
    startDate: new Date("2023-12-17"),
  },
];

const repeatSeasonsForNextYear = (seasons: any) => {
  const nextYearSeasons = seasons.map((season: any) => {
    const newStartDate = new Date(season.startDate);
    newStartDate.setFullYear(newStartDate.getFullYear() + 1);

    return {
      ...season,
      startDate: newStartDate,
    };
  });

  return [...seasons, ...nextYearSeasons];
};

const extendedSeasonsData = repeatSeasonsForNextYear(seasonsData);

const seasons = extendedSeasonsData.map((season) => ({
  ...season,
  imageSrc: `/seasons/${season.name.toLowerCase().replace(/ /g, "-")}.jpg`,
}));

function App() {
  return (
    <div className="App">
      <SeasonsCarousel seasons={seasons} />
    </div>
  );
}

export default App;
